<template>
  <div>
     <div class="overflow-auto">
    <div class="padre-titulo">
      <h1>{{titulo}}</h1>
    </div>
    <b-card>
      <div class="row">
        <b-card
        v-for="(item, index) in nanas" :key="index"
          :img-src="url + item.idnana"
            img-alt="Image"
            img-top
            tag="article"
            class="tarjeta mb-2"
            style=" max-width: 20rem; border: none;"
            :current-page="currentPage"
            :per-page="perPage"
            id="cardNana"
        >      
         <b-card-title style="font-size: 18px;">{{ item.primer_nombre.replace(/(^|\s)\S/g, l => l.toUpperCase()) + ' ' + item.primer_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()) }}</b-card-title>
         <!--<b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title>-->
         <b-card-text class="mt-3">
             {{ 'Edad: '+ ' ' + item.edad }}<br>
             {{ 'Años de experiencia: '+ ' ' + item.anios_experiencia }}
         </b-card-text>
        <!--  <perfil  :data="item.idnana"    /> -->
         <b-button  style="background-color: #00B290 ;" href="#" @click="abrirModal(item.idnana)" >Ver Nana</b-button>          
        </b-card>       
      </div>   
      <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="cardNana"
      align="center"
    ></b-pagination>
    </b-card>
     </div>
    <div v-if="modal">
        <ModalPerfil :abrirModal='modal' :data="dataNana" :id="stade"  @cerrarModal="cerrarModal"  />
    </div>
  </div>
</template>

<script>
import { fdcService } from "@/api/dispatcher.service";
import ModalPerfil from '@/components/ModalPerfil.vue';
// @ is an alias to /src

export default {
  name: "",
  components: {
    ModalPerfil,
  },

  data() {
    return {
      perPage: 3,
      currentPage: 2,
      tarifas: [],
      servicios: [],
      show_entre: false,
      show_garan: false,
      show_psico: false,
      nanas: [],
      url: "https://admincn.fabricadecodigo.dev:8081/imagen/nana/",
      dataNana: null,
      modal: false,
      titulo: null,
      stade: null
    };
  },

  created() {
    this.listarTarifas();
    this.tituloo();
    window.console.log('COTENIDO DE PARAMETRO ',this.$route.params.state)
    this.stade = this.$route.params.state
  },
  
  methods: {
    tituloo(){
      if(this.$route.params.state == 0){this.titulo = 'Nanas Puertas Adentro'}
      if(this.$route.params.state == 1){this.titulo = 'Nanas Puertas Afuera'}
      if(this.$route.params.state == 2){this.titulo = 'Nanas Por Días'}
      if(this.$route.params.state == 3){this.titulo = 'Nanas Por Horas'}
    },
    async listarTarifas() {
      this.$vs.loading({type: "corners", text:'Cargando...'});
      let resp = await fdcService.execute("tipoServicio",[this.$route.params.state],{},{});
      window.console.log("respuesta de listar nanas", resp);
      this.nanas = []
      if(resp.resultado == 'ok'){
        this.$vs.loading.close();
          this.nanas = resp.data          
          window.console.log("Contenido de listar nanas", this.nanas);
      }else{
          this.$bvToast.toast('Falló, reitente o contacte a soporte', {
              title: 'Operación fallida',
              variant: 'danger',
              solid: true
          })
      }        
    },
    async datosPerfil(id){
            let resp = await fdcService.execute("nanaPerfil",[id],{},{});
            window.console.log("respuesta perfil nana", resp);
            if(resp.resultado == 'ok'){
                if(id == 2){this.idi = 2}
                this.dataNana = resp.nana
                this.modal = true
            }
             
        },
    abrirModal(id){
      this.datosPerfil(id)
    },
    cerrarModal(){
      this.modal = false
    },    
   
    
  },
};
</script>
<style >
.padre-titulo {
  margin: 1rem;
  padding: 1rem;
  /* IMPORTANTE */
  text-align: center;
}
</style>
