<template>
  <servicio-vue></servicio-vue>
</template>

<script>

import ServicioVue from '../components/Servicio.vue'

// @ is an alias to /src

export default {
  name: 'servicios',
  components: {
    ServicioVue,
  
  },
 
}
</script>
<style >

</style>
